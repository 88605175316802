
.crslBackground{
    height: 30vw;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    min-height: 200px;
    border-radius: 20px;
  }

  .btnsAr .swiper-button-prev {
    background-image: url("../Images/arrow-right.svg") !important;
    background-repeat: no-repeat;
    background-color: rgba(106, 38, 38, 0.476);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
  }
  .btnsAr  .swiper-button-prev:hover {
 
    background-color: rgba(106, 38, 38, 1);
   
  }
  .btnsAr   .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}
  
.btnsAr .swiper-button-next {
    background-image: url("../Images/arrow-left.svg") !important;
 
    background-repeat: no-repeat;
    background-color: rgba(106, 38, 38, 0.476);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
    
  }
  .btnsAr .swiper-button-next:hover {
 
    background-color: rgba(106, 38, 38, 1);
   
  }
  .btnsAr .swiper-button-next:after, .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}
.btnsEn .swiper-button-prev {
    background-image: url("../Images/arrow-left.svg") !important;
    background-repeat: no-repeat;
    background-color: rgba(106, 38, 38, 0.476);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
  }
  .btnsEn  .swiper-button-prev:hover {
 
    background-color: rgba(106, 38, 38, 1);
   
  }
  .btnsEn   .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}
  
.btnsEn .swiper-button-next {
    background-image: url("../Images/arrow-right.svg") !important;
 
    background-repeat: no-repeat;
    background-color: rgba(106, 38, 38, 0.476);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
    
  }
  .btnsEn .swiper-button-next:hover {
 
    background-color: rgba(106, 38, 38, 1);
   
  }
  .btnsEn .swiper-button-next:after, .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}
.swiper{
    height: auto !important;
    max-height: 30em;
 
}
.myCarousel .swiper{
    height: auto !important;

}
.mpNav{
display: flex;
justify-content: space-between;
align-items: center;
text-align: right;
flex-wrap: wrap;
padding-top: 2%;
}
.mpNav img{
    position: relative;

    transform: translateY(-30%);
    width: 100px;
}

.langBtns {

    cursor: pointer;
    margin: 1em;
}
.errMsg{
    font-family: 'Cairo';
    color: brown;
}
.langBtns:hover {


    color: #e47200 !important;

}
.langBtns:hover .langBtn{


    color: #e47200 !important;

}
.langBtn{
    background:transparent;
    border: none;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
 
    letter-spacing: -0.3px;
 
    color: #4F4F4F;
    position: relative;
    top: 3px;
  
}
.langSwitch p{
    display: inline-block;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
 
}

.mpP-1{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;

text-align: right;
letter-spacing: -0.3px;

color: #331900;
width: 100%;
margin-bottom: 0.5em;
}
.mpP-2{
font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 40px;
text-align: right;
letter-spacing: -0.3px;
margin-top: 0;
color: #331900;}

.mpMenuOptions{
    position: relative;
cursor: pointer;
filter: drop-shadow(2px 2px 20px rgba(0, 0, 0, 0.35));
border-radius: 15px;
display: flex;
  flex-direction: Column ;
align-items: center;
text-align: center;
font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: calc(16px + 1vw);
line-height: 35px;
letter-spacing: 0.2px;

color: #FFFFFF;

height: 0;
width: 100%;
padding-top: 70%;
background-repeat: none;
background-size: 100% 100%;
}
@media screen and (max-width: 575px) {
    .tapChoice{
        margin-top: 0.5em;
    }
    .mpMenuOptions {
      font-size: 36px;
    }


    .mpP-1{
     
    font-size: 16px;
    margin-bottom: 0;
    

    }
    .mpP-2{

        font-size: 23px;
    }
  }

.alignP-3{
    display: flex;
   
    justify-content: center;
   margin-bottom: 2em;
}

.mpMenuOptions p{
position: absolute;
top:10%;

}



.mpMenuOptions div{
    background: linear-gradient(180deg, rgba(99, 50, 53, 0) 0%, rgba(99, 50, 53, 0.03) 45.83%, #633235 100%);
    filter: drop-shadow(0px 1px 24px rgba(0, 0, 0, 0.15));
    border-radius: 15px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    border-radius: 15px;

height: 104px;
width: 100%;
}
.mpMenuOptions:hover{
    filter: drop-shadow(2px 2px 20px rgba(255, 255, 255, 0.35));
}
.mpMain{
    min-height: 25vh;
    margin-bottom: 7%;
}
.mainCategories{
  
    background-color: white;
    border-radius: 10px;
    padding-bottom: 0.5em;
    cursor: pointer;
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
}
.mainCategories p{
  
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 45px;

    letter-spacing: -0.3px;
    margin-bottom: 0;
    color: #E47100;
    display: inline-block;
}
.mainCategories .enarrow-button > .arrow {

    border-right: 3px solid #e47200;
    border-bottom: 3px solid #e47200;
}
.mainCategories .arrow-button > .arrow {

    border-right: 3px solid #e47200;
    border-bottom: 3px solid #e47200;
}
.categoryTo{
display: flex;
align-items: center;
justify-content: space-between;

}

.categoryImg{
height: 17vw;
min-height: 200px;
background-position: center;
background-size: cover;
background-repeat: no-repeat;
border-radius: 10px 10px 0 0;
}


.BottomNav{
background: #FFFFFF;
box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.07);
border-radius: 30px;
height: 75px;
display: flex;
justify-content: space-around;
align-items: center;
flex-shrink: 0;
width: 100%;
position: fixed;
bottom: 0;
z-index: 10;
}
.container-fluid2 {
    padding-bottom: 120px;
    margin-bottom: 120px;
}
.BottomNav p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    /* identical to box height */
    
    text-align: right;
    letter-spacing: -0.3px;
    
    /* Gray 4 */
    
    color: #BDBDBD;
   
    margin-bottom: 0;
}

.BottomNav div{
    text-align: center;
    cursor: pointer;
    top: 5px;
}


.BottomNav div:hover p{
    color: #633235;

}
.BottomNav div:hover path{
    fill: #633235;
    }
   /* MainMenu */
  .removePadd{
      padding: 1em;
 padding-bottom: 0 !important;
  }
.mpNav .mmBackTitle{
margin-bottom: 0;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
   display: inline-block;
    top: 1.7em;
    right: auto !important;
    left: 4em;
    margin-left: 1em;
}
.mpNav .mmBack{


    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: none;
    background-color: white;
    border-color: white;
    top: 2em;
 
  
    left: 1em;
    transform: rotate(180deg);
    transform-origin: 50% 50%;
}
.mpNav .mmBack:hover{

color: #E47100;

} 


.mmBackButton{
    position: relative;
    height: 50px;
    margin-bottom: 3em;
}
.mmBackTitle{
  right:auto !important;
  left: 4em;
}

.mpNav .mmBackTitleAr{

    
    margin-left: 0 !important;
    margin-right: 1em;
}
.mmBack{
    right:auto !important;
    left:1em;
    transform: rotate(180deg);
    transform-origin: 50% 50%;
  }
  .mmBackAr{
    top: 2em;
    right: 1em !important;
    left: auto !important;
    transform: rotate(360deg) !important;
    transform-origin: 50% 50%;
}
.mmBackButton p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    position: absolute;
    top:1.7em;
    right:4em;
}
.mmBackButton button{
    position: absolute;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    border: none;
    background-color: white;
    border-color: white;
    top:2em;
    right:1em;
     }
     .mmBackButton button:hover{
         color: #E47100;
          } 
 .slider  button{
border: none;
background: #FFFFFF;
box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.1);
border-radius: 53.5px;
font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 45px;
display: flex;
align-items: center;
text-align: right;
letter-spacing: -0.3px;
color: #331900;
padding: 3px 30px;

   }

   .mmFoodItem{
    background: white;
    box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    text-align: right;
    padding: 1em 0.2em;
    position: relative;
   }
   .mmFoodItem img{
width: 90%;
height: auto;
float: right;
border-radius: 8px;
margin-left: 5%;


   }
   .mmFoodItem .wrapText2{
   
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left !important;
  direction: rtl !important;
  overflow: hidden;
  height: 2em;
  margin-bottom: 1em;
       }
       .mmFoodItem .enwrapText2{
   
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left !important;
      direction: ltr !important;
      overflow: hidden;
      height: 2em;
     
           }

       .wrapText{
   
       
    
     
           }
    


   .mmFoodItem p{
       margin-bottom: 0.5em;
   }
   .mmFoodItem .center{
    display: flex;
    justify-content: center;
    align-items: center;
}
   .mmFoodItem .col-6{
      /* padding-left: 0 !important;*/
   }
.mmName{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 30px;
text-align: right;
letter-spacing: -0.3px;
color: #331900;
}
.mmDetails{
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 28px;
text-align: right;
letter-spacing: -0.3px;
color: #828282;

}
.mmBottom{
  
}

.mmBottom button{
    background: #FCF1E5;
    border-radius: 0px 10px;
    border: none;
    color: orange;
    position: absolute;
    bottom: 0;
    left:0;
    padding: 10px;
}
.enmmButton{
    left:auto !important;
    right: 0;
    border-radius: 10px 0px !important;
}


.mmBottom p{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 37px;
text-align: right;
letter-spacing: -0.3px;

color: #E47100;

} 
   /* horz nav */
   div.scrollmenu {
    background-color: transparent;
    overflow: auto;
    white-space: nowrap;
    margin: 1rem 0;
    direction: rtl;
  }
  

  .scrollmenu{
position: sticky;
top: 0px;
padding: 0.5em 0;
z-index: 10;
background-color: #fafafa !important;
  }

  div.scrollmenu div {
    display: inline-block;
    color: white;
    text-align: center;
    padding:13px;
    text-decoration: none;
  }


    div.scrollmenu button {
  background-color: white;
  border: none;
  box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.1);
border-radius: 53.5px;
font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 16px;

align-items: center;

letter-spacing: -0.3px;
text-overflow: ellipsis;
color: #331900;
padding: 10px 0;
width: 150px;
white-space: nowrap;
overflow:hidden;
  }
  div.scrollmenu button:hover{
    background-color: #f88819;
    color: white;
    
  
    }


    div.scrollmenu .activeBtn{
        background-color: #f88819;
        color: white;
        
      
        }
        /*recommended*/
        .spRecomend p {
            color: #db095b;
            font-family: Cairo;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            text-align: end;
            letter-spacing: -.3px;}

            .companyServices .bckg, .medyafMenu .bckg {
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 8px;
                width: 100%;
            }
            
            .medyafMenu .bckg {
                height: 13vw;
            }
            .spRecomend .swiper {
                align-items: center;
                height: 18em!important;
                overflow: visible;
                overflow: initial;
            }
            .recommendSlider   .swiper-slide {
                width: 170px!important;
            }
            .myCarousel .swiper, .swiper {
                height: auto!important;
            }
            .swiper {
                max-height: 30em;
            }

        .spRecomend {
            overflow-x: clip;
        }
             .medyafMenu {
                min-width: 170px!important;
            }
            
            .medyafMenu {
                background: #fff;
                border-radius: 12px;
                min-width: 236px;
                text-align: center;
            }
            .reccomendation {
                box-shadow: 0 0 12px rgb(0 0 0 / 12%);
                padding: 1em 1em 0;
            }
            .recommendBasket {
                min-width: none!important;
                padding: 0!important;
            }
       .medyafMenu .bckg {
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 8px;
                width: 100%;
            }
            .medyafMenu .bckg {
                height: 10vw;
                min-height: 130px;
            }
            .medyafMenu p {
                font-family: Cairo;
                font-style: normal;
                letter-spacing: -.3px;
                text-align: center !important;

            }
            .spRecomend{
                height: 17em;
            }
            
            .medyafMenu p {
                color: #e47100;
                font-size: 18px;
                font-weight: 700;
                line-height: 34px;
            }
            .medyafMenu p {
                font-weight: 600!important;
            }
         
         
            .recommend-name, .recommend-price {
                font-family: Cairo;
                font-style: normal;
                letter-spacing: -.3px;
            }
            .recommend-name {
                color: #331900!important;
                font-size: 15px!important;
                font-weight: 700!important;
                line-height: 31px;
                margin-bottom: 0;
            }
            .medyafMenu p {
                font-family: Cairo;
                font-style: normal;
                letter-spacing: -.3px;
            }
            
         
            .medyafMenu p {
                font-weight: 600!important;
            }
            .spRecomend p {
                color: #db095b;
                font-family: Cairo;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: -.3px;
            }
            .recommendBasket p {
                line-height: 29px!important;
                margin: 0!important;
            }
            .recommend-price {
                color: #e47100!important;
                font-size: 14px!important;
                font-weight: 600!important;
                line-height: 34px;
            }





.recommmendAr .swiper-button-next {
    background-image: url("../Images/small-left.svg") !important;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
  
  
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.14);
   
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
  }
  .recommmendAr  .swiper-button-next:hover {
  
    box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.24);
   
  }
  .recommmendAr   .swiper-button-next:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
  }
  
  
  .recommmendAr .swiper-button-prev {
    background-image: url("../Images/small-right.svg") !important;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
  
  
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.14);
   
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
  }
  .recommmendAr  .swiper-button-prev:hover {
  
    box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.24);
   
  }
  .recommmendAr   .swiper-button-prev:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
  }
  
  
  .recommmendEn .swiper-button-prev {
    background-image: url("../Images/small-left.svg") !important;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
  
  
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.14);
   
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
  }
  .swiper-button-prev.swiper-button-disabled {
  display: none;
  
  }
  
  .swiper-button-next.swiper-button-disabled {
    display: none;
    
    }
  .recommmendEn  .swiper-button-prev:hover {
  
    box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.24);
   
  }
  .recommmendEn   .swiper-button-prev:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
  }
  
  
  
  .recommmendEn .swiper-button-next {
    background-image: url("../Images/small-right.svg") !important;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
  
  
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.14);
   
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
  }
  .recommmendEn  .swiper-button-next:hover {
  
    box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.24);
   
  }
  .recommmendEn   .swiper-button-next:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
  }
  

  
     /* scroll Basket */

     .confirm{
  
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 34px;
        width: 40%;
      
        letter-spacing: -0.3px;
    
        color: white;
        background: #E47100;
        border-radius: 10px;
    border: none;
        padding: 0.3em 2em;
        margin: 0 0.5em;
    }
    .confirm:hover{
        background: #f17e0b;
    
    }
    
    .cancel{
      
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 34px;
        
     width: 40%;
        letter-spacing: -0.3px;
    
        color: #E47100;
        background: white;
        border-radius: 10px;
    border: 1px solid #e47100;
        padding: 0.3em 2em;
    margin: 0 0.5em;
    }
    .cancel:hover{
        background: #f17e0b;
        color: white; 
    }



.close-modal {
    position: absolute;
    top: -16px;
    right: -6px;
  border: none;
  background: white !important;
  border-radius: 50% !important;
  padding: 0 0 !important;
  color: #e47100 !important;
  width: auto !important;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.close-modal:hover {
    color: #f88f25;

}
#giftImg{
margin: auto;
height: 300px;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.giftModal{
    background: transparent !important;
position: static !important;
transform: translateY(0) !important;
}
.giftModal  p{
    margin-bottom: 0 !important;
}
.partyImg{
    width: 43px !important;
    height: 35px !important;
position: relative;
bottom: 5px;
    margin: 0 !important;
    float: none !important;
}
    .cancelOrder{ 
  
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.3px;
        color: #331900;
        margin-bottom: 0;
    }


.loaderDiv{
text-align: center;
height: 70px;
padding-top: 1em;
}
.loaderDiv span{
display: inline-block !important;

}
.offerIcon{
    display: block;
text-align: center;
background-color: #E47100;
padding: 14px 1em;
border-radius: 4px 4px 0 0;
}
.offerIcon img{
    display: inline-block;
    width: 100px !important;
    float: none !important;
}
.offer-middle{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;


letter-spacing: -0.3px;

color: #E47100;

}

.odderBckg{

  
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}


     .Modal, .modalOverlay {
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 11;

    }
    .offer{


font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 20px;

letter-spacing: -0.3px;

color: #331900;

    }

    .offerAfter{

font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 26px;

letter-spacing: -0.3px;


color: #E47100;

    }
    .offerBefore{
        text-decoration: line-through;
        text-decoration-color:#4F4F4F;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 26px;

letter-spacing: -0.3px;

color: #aba9a9;
    }
    .offerdetail{
        display: flex;
        justify-content: space-around;
        margin-bottom: 2em;
    }
    .Modal .continue{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 34px;

        letter-spacing: -0.3px;
     color: #FFFFFF;
     background: #E47100;
     border-radius: 14px;
     border: none;  
     width: 40%;
     padding: 0.3em 0;
    }
    .Modal .continue:hover{
        background: #ee8419;  
    }
    
    
    .modalOverlay {
        background: rgba(49,49,49,0.8);
    }
    .modal-content {
     
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        background: #f1f1f1;
      
        border-radius: 20px;
      
        z-index: 12;
    
       display: unset;
 
   
    }

    .modal-contentEn{
        position: absolute;
        top: 50%;
     left: unset;
        transform: translate(0, -50%);
        line-height: 1.4;
        background: #f1f1f1;
      
        border-radius: 20px;
      
        z-index: 12;
    
       display: unset;
       width: 90%;
       max-width: 400px;
       min-width: 330px;
       margin-left: auto;
       margin-right: auto;
    }

    .modalOption{

        display: flex;
        justify-content: space-around;
       
        margin-top: 2em;
    }
    .vCenter{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
  
 

  div.Bscrollmenu {
    background-color: transparent;
    overflow: auto;
    white-space: nowrap;
    margin: 1rem 0;

   align-items: flex-start;
   direction: rtl;
  }
  div.enBscrollmenu {
    background-color: transparent;
    overflow: auto;
    white-space: nowrap;
    margin: 1rem 0;

   align-items: flex-start;
   direction: ltr !important;
  }

 .BscrollTitle{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 45px;
  text-align: right;
    letter-spacing: -0.3px;

    color: #4F4F4F;
    margin-bottom: 1em;
  }
  .enBscrollTitle{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 45px;
  text-align: left;
    letter-spacing: -0.3px;

    color: #4F4F4F;
    margin-bottom: 1em;
  }
  
  div.Bscrollmenu .Div {
    display: inline-flex;
    background: #F3F3F3;
    border-radius: 15px;
    text-align: center;
    padding: 10px;
    margin-left: 50px;
    text-decoration: none;
    height: 120px;
    width: 17em;
    flex-wrap:nowrap;
    justify-content: space-between;
    align-content: center;
    cursor: pointer;
    direction: ltr;
  }

  div.Bscrollmenu img {
   height: auto;
   width: 130px;
   float:right;
   filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));
border-radius: 8px;

  }

  div.Bscrollmenu .BscrollP{
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
margin: 0 15px;
  }

  div.Bscrollmenu .bsP-1{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
margin-bottom: 0;
text-align: right;
letter-spacing: -0.3px;
display: block;
/* #331900 */

color: #331900;

white-space: normal;
   }

   div.Bscrollmenu .bsP-2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    /* identical to box height */
  
    text-align: right;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    color: #E47100;
 
   }




   div.enBscrollmenu .Div {
    display: inline-flex;
    background: #F3F3F3;
    border-radius: 15px;
    text-align: center;
    padding: 10px;
    margin-right: 50px;
    text-decoration: none;
    height: 120px;
    width: 17em;
    flex-wrap:nowrap;
    justify-content: space-between;
    align-content: center;
    cursor: pointer;
    direction: rtl;
  }

  div.enBscrollmenu img {
   height: auto;
   width: 130px;
   float:right;
   filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));
border-radius: 8px;

  }

  div.enBscrollmenu .BscrollP{
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
margin: 0 15px;
  }

  div.enBscrollmenu .bsP-1{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
margin-bottom: 0;
text-align: left;
letter-spacing: -0.3px;
display: block;
/* #331900 */

color: #331900;
cursor: default;
white-space: normal;
   }

   div.enBscrollmenu .bsP-2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    /* identical to box height */
  
    text-align: left;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    color: #E47100;
    cursor: default;
   }


   @media screen and (max-width: 540px) {
    .bTop {
        text-align:unset !important; 
       
        display: block !important;
        margin-bottom: 0 !important;
    }
.bTop p{
    margin-bottom: 0;
}
 
  }


   /* CartItem */
.ciImage .dmfLfw{
    z-index: -10;
}
   .phoneForm input{
    background: #FFFFFF;
    box-shadow: 0px 6px 33px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 26px;
letter-spacing: -0.3px;
color: #828282;
border: none;
width: 90%;
padding: 0.6em 0;
padding-left: 20px;
margin-bottom: 0.5em;

 }

 .phoneForm span{
    color: rgb(169, 15, 15);
    display: block ;
    margin-bottom: 2em;
 }


 .landingForm input:focus{
border: 2px solid #E47100 ;
outline: none !important;
 }
.alignTop{
    display: flex;
    align-items: center;
    justify-content: end;
}

.ciImage img{
width: 100vw;
height: 46vw;
z-index: -10;
}
.ciImage .itemImage{
    width: 100vw;
    height: 46vw;
    z-index: -10;
    background-repeat: no-repeat ;
    background-position:  center;
    background-size: cover;
    }

@media screen and (max-width: 623px) {
    .ciImage .itemImage{
     
        height: 60vw;
       
        }
      }
.addAlert{
    text-align: center;
    transition: all 1s  ease-in;
    background-color: #e47100;
 
position: absolute;
left: 0; 
right: 0; 
margin-left: auto; 
margin-right: auto; 
bottom: 25%;
width: 29em;
max-width: 90vw;
padding: 0.5em;
box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.05);
border-radius: 10px;

}
.errAlert{
    z-index: 99;
    bottom: 10%;
  padding: 3em 0;
    display: flex;
   justify-content: center;
   left: -6px;
}
.errAlert span{

    color: white !important;

}
.vAlignText{

  position: relative;
  top: 50%;
  transform: translateY(-50%);
 
}
.alertImg{


    height: 110px;
   
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
}
.addAlert span{
    color: #633235;
    font-size: 20px;
}  

.enAlert p{

    text-align: left !important;
}
.addAlert p{
  color: white;

 
  font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
margin-bottom: 0.5em;
border-radius: 10px;
line-height: 20px;
text-align: right;

}
.alert-hidden {
   opacity: 0;
  display: none;
  position: absolute;
 
  }
.ciImage button{
   position: absolute;
   border-radius: 50%;
   height: 50px;
   width: 50px;
   border: none;
   background-color: white;
   border-color: white;
   top:2em;
   right:1em;
    }
.delBtn{

    color:#420F12;
    position:absolute;

}
.delBtn:hover{

    color: #E47100;
   
    
}
    .ciBackbtn{
right: auto !important;
left: 1em;
transform: rotate(180deg);
    transform-origin: 50% 50%;
    }
    .ciImage button:hover{
        color: #E47100;
         }
.cartItem{
position: relative;
}

.ciDetails{
    width: 100vw;
   
    background: #FFFFFF;
box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.05);
border-radius: 50px;
position: absolute;
top: 85%;
}
.ciTopFlex{
    display: flex !important;
    justify-content: flex-start;
    align-items: flex-start;

}

.btn .btn1{
    background: #633235;
    border-radius:33px 0px 0px 33px; 
    height: 55px;
  
    border: none;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 52px;
text-align: right;
letter-spacing: -0.3px;

color: #FFFFFF;
padding: 0 30px;
}
.btn .enbtn1{
    background: #633235;
    border-radius:0px 33px 33px 0px; 
    height: 55px;
  
    border: none;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 52px;
text-align: right;
letter-spacing: -0.3px;

color: #FFFFFF;
padding: 0 30px;
}

.btn .btn2{
    background: #633235;
    border-radius:0px 33px 33px 0px; 
    height: 55px;

    border: none;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 52px;
text-align: right;
letter-spacing: -0.3px;

color: #FFFFFF;
padding: 0 35px;
}

.btn .enbtn2{
    background: #633235;
    border-radius:33px 0px 0px 33px; 
    height: 55px;

    border: none;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 52px;
text-align: right;
letter-spacing: -0.3px;

color: #FFFFFF;
padding: 0 35px;
}

.btn button:hover{
color: #E47100;
}

.btn p{
   display: inline;
   font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 52px;
text-align: right;
letter-spacing: -0.3px;
height: 55px;

color: #FFFFFF;
background-color: #633235;
padding: 1px 10px;
padding-bottom: 1.7px;
cursor: default;
}
@media screen and (max-width: 623px) {
 
      }

.ciTop{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ciP1{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: right;
    letter-spacing: -0.3px;
    
    /* #331900 */
    
    color: #331900;
    margin-bottom: 0; 
    
}

.enciP1{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    letter-spacing: -0.3px;
    
    /* #331900 */
    
    color: #331900;
    margin-bottom: 0; 
   
}
.ciP1Flex{
    display: flex;
    justify-content: center;
}

.ciP2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 45px;
    text-align: right;
    letter-spacing: -0.3px;
    color: #828282;
   display: block;
}
.enciP2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 45px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #828282;
   display: block;
}

.ciDetails select{
    display: block;
    width: 100%;
    border: 1px solid #E0E0E0;
border-radius: 15px;
background-color: white;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 45px;
/* identical to box height */

text-align: right;
letter-spacing: -0.3px;

/* #633235 */

color: #633235;
margin-bottom: 2em;
padding: 0.2em;
cursor: pointer;
}
.ciDetails select:focus{
    border: 2px solid #E47100;

}
.ciDetails select:focus{
    border: 2px solid #E47100 !important;
    outline: none;

}

.ciP3{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 45px;
text-align: right;
letter-spacing: -0.3px;

color: #4F4F4F;

}

.ciBottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5%;
}
.enflex label{
padding-left: 0.5em;
}

.ciB-1{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 29px;
/* identical to box height */

text-align: right;
letter-spacing: -0.3px;

color: #E47100;
margin-bottom: 0;
}

.ciB-2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 45px;
    /* identical to box height */
    
    text-align: right;
    letter-spacing: -0.3px;
    
    /* Gray 3 */
    
    color: #828282;
    margin-bottom: 0;
}

.ciB-3{
   
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 24px;

text-align: right;
letter-spacing: -0.3px;

/* #331900 */

color: #331900;
}



.enciB-2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 45px;
    /* identical to box height */
    
    text-align: left;
    letter-spacing: -0.3px;
    
    /* Gray 3 */
    
    color: #828282;
    margin-bottom: 0;
}

.enciB-3{
   
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 24px;

text-align: left;
letter-spacing: -0.3px;

/* #331900 */

color: #331900;
}

.ciBottom button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 49px;
    /* identical to box height */
    
    text-align: center;
    letter-spacing: -0.3px;
    
    /* #FFFFFF */
    
    color: #FFFFFF;
    background: #E47100;
    border-radius: 14px;
    border: none;
    padding: 0.5em 1em;
    width: 90vw;
    margin: auto 5%;
  
}

.ciBottom button:hover{

    background-color: #f88819;
}

.ciBottom .center{
    display: flex;
    align-items: center;
    justify-content: center;
}

  /* Basket */

.paymentIframe{
    height: 100vh;
    width: 100%;
}

  .bTop{
     text-align: center; 
     margin-bottom: 1em;
     margin-top: 1em;
     position: relative;
     display: flex;
     justify-content: space-between;
     padding: 1em 0;
  }
  .bdeleteAll{
    
      position: static !important;
      background: #FFFFFF !important;
      border: 2px solid #633235 !important;
border-radius: 10px !important;
font-family: 'Cairo' !important;
font-style: normal !important;
font-weight: 600 !important;
font-size: 18px !important;
line-height: 34px !important;
letter-spacing: -0.3px !important;
color: #633235 !important;
padding: 0 1em;
height: 2.5em;
  }

  .bdeleteAll:hover{
    background: #633235 !important;
    color: #FFFFFF !important;
  }

  .bTop button{
    position: absolute;
    border-radius: 50%;
   
    border: none;
    background-color: white;
    border-color: white;
 
    top: 12px;

   right: 0;
     }
     .bTop button:hover{
        color: #E47100;
         } 
  .bTop p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 52px;

    letter-spacing: -0.3px;
    color: #331900;
  }
  .Basket .center{
 
   display: flex;
justify-content: center;

}
.bEmpty{
    text-align: center;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 60px;

letter-spacing: -0.3px;
color: black;
position: relative;

    transform: translateY(50%);
    height: 50vh;
}

.bEmpty button{
    background: rgba(228, 113, 0, 0.1);
    border-radius: 10px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 26px;
line-height: 49px;
/* identical to box height */


letter-spacing: -0.3px;

/* #E47100 */

color: #E47100;
border: none;
padding: 0.5em 2em;
}
.bBottomNavEmpty{
    position: absolute !important;
   bottom: -105px;
    width: 100%;
}
.bEmpty button:hover{
    background: rgba(228, 113, 0, 0.2);
}

.Basket img{
    width: 90%;
    height: auto;
    float: right;
    border-radius: 8px;
    margin-left: 5%;
    
       }

       .bName{
  
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 41px;
        text-align: right;
        letter-spacing: -0.3px;

        color: #331900;
       }

       .enbName{
  
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 41px;
        text-align: left;
        letter-spacing: -0.3px;
margin-bottom: 0;
        color: #331900;
       }
       .bDetails{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 25px;
        text-align: right;
        letter-spacing: -0.3px;
    
        color: #828282;
       }

       .bFlex{
           display: flex;
           justify-content: space-between
       }

       .bFlex p{
           margin-bottom: 0.5em;
       }

       .bPrice{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 37px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.3px;
        
        /* #331900 */
        
        color: #331900;
       }
       .bItem{
     background-color: white;
        padding: 1em;
        margin: 1em 0;
       }
       .bCenter{
           display: flex;
           justify-content: center;
           align-items: center;
       }

       .bBtn{
           cursor: pointer !important;
           border: none;
       background: #633235;
           border-radius: 5px;
           font-family: 'Cairo';
           font-style: normal;
           font-weight: 700;
           font-size: 20px;
           line-height: 37px;
          
           text-align: center;
           letter-spacing: -0.3px;
           
           color: white;
           padding :0 15px;
          }
       
          .bBtn:hover{

            background-color: #532a2d;
            color: #f88819;
          }
       .bQuan{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 37px;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: -0.3px;
        display: inline;
        color: #331900;
        padding: 0 0.8em;
        
       }
       .bNote{
           background-color: white;
           padding: 1em;
           width: 100%;
           text-align: right;
         
       }
       .bDiv2{
       
       }
.bNote input[type="text"]{
    font-family: 'cairo';
   
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 41px;
    text-align: right;
    letter-spacing: -0.3px;

    color: #828282;
    border: 1px solid #E0E0E0;
border-radius: 10px;
width: 100%;
padding: 4em 1em;
padding-top: 1em;
}
.enbNote{
    background-color: white;
    padding: 1em;
    width: 100%;
    text-align: left;
    direction: ltr;
}
.basketItemImg{

    width: 100%;
    height: 25vw;
    max-height: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
}
.recommendImg{
    width: 100%;
    height: 13vw;
    max-height: 100px;
    min-height: 99px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;

}

.foodItemImg{
    width: 100%;
    height: 15vw;
    max-height: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;


}

.enbNote input[type="text"]{
    font-family: 'cairo';
   
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 41px;
    text-align: left;
    letter-spacing: -0.3px;

    color: #828282;
    border: 1px solid #E0E0E0;
border-radius: 10px;
width: 100%;
padding: 4em 1em;
padding-top: 1em;
}

 input[type="text"]:focus{
    border: 2px solid #E47100 !important;
    outline: none;

}

.paymentTYpe input{
cursor: pointer;
width: auto;
}
.paymentTYpe p{
margin-top: 1em;
    margin-bottom: 0.2em !important;
}
.paymentTYpe label{
   margin: 0 0.5em;
   font-family: 'Cairo';
   font-style: normal ;
   font-weight: 400 ;
   font-size: 20px ;
 
 
   letter-spacing: -0.3px ;
   
   color: #828282 ;
 

    }
    .promoCode input{

      padding: 0.5em !important; 
      margin-bottom: 0.5em; 
   
    }
  
  
    .orderRec{

background: white;
border-radius: 10px;
width: 100%;
text-align: center;
position: relative;
top: 50%;
transform: translateY(-50%);
padding-bottom: 2em;
    }
    .orderRec p{

        font-family: 'Cairo';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
max-width: 300px;
margin: auto;  

        letter-spacing: -0.3px;
       color: #331900;

    }


    .orderRec button{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
   
        text-align: center;
        letter-spacing: -0.3px;
       color: #e47200;
        background: #ffff;
        border-radius: 14px;
        border: none;
        padding: 0.5em 1em;
      
      
border: 1px solid #e47200;

    }  
    .orderRec div p{

display: inline-block;
    }
    .freeList{
display: flex;
justify-content: center;
margin-top: 1em;

    }
    .freeList li{
       text-align: left;

       font-family: 'Cairo';
       font-style: normal;
       font-style: normal ;
       font-weight: 400 ;
       font-size: 16px ;
       
       line-height: 27px;
 
       letter-spacing: -0.3px;
      color: #331900 !important;
            }
            .invoiceSecondTitle{
                font-family: 'Cairo';
                color: #616060;
                font-weight: 600;
                border-bottom: 1px solid #E0E0E0;
                padding-bottom: 1em;
              
              }
              .invoiceSecondTitle p{
                margin-bottom: 5px;
              }
    .gift-p{
        display: block !important;
        font-family: 'Cairo';
font-style: normal !important;
font-weight: 400 !important;
font-size: 16px !important;

letter-spacing: -0.3px;

color: #828282 !important;
    }

    .footerLink{
        font-weight: 600;
        font-family: 'cairo';
        font-size: 14px !important;
        text-align: end;
        padding: 10px 24px;
        background:  #F2F4F7;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .footerLink p{
        margin-bottom: 0;
    }
 .footerLink a,.footerLink span {
color:   #E47100 !important
 }
    .err{
        color: brown;
        font-family: 'Cairo';
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 20px !important;
        letter-spacing: -0.3px;
        margin: 1em 0 !important;
    }
    .orderRec div span{

        margin: 0 0.2em;
            }
         .orderRec div{

         }  

    .orderRec button:hover{


        color: white;
        background-color: #e47200;
    }

    .promoCode p{

      margin-top: 1em; 
      margin-bottom: 0.5em !important;
      }
   .bNote p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 45px;
    text-align: right;
    letter-spacing: -0.3px;
    
    color: #4F4F4F;
    display: block;
    margin-bottom: 1em;
   }
   .enbNote p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 45px;
    text-align: left;
    letter-spacing: -0.3px;
    
    color: #4F4F4F;
    display: block;
    margin-bottom: 1em;
   }
   .cartNumber{
       background-color: #E47100;
       color: white;
       font-family: 'Cairo';
       font-style: normal;
       font-weight: 700;
       border-radius: 100%;
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
       position: absolute;
       left: 45px;
       top: -8px;
   }
.discount{

position: relative;
display: inline-block !important;
}
.alignDisc img{
    height: 40px;
   
    width: auto;
    margin: 0;
  
}
.postDiscount{
  display: inline-block !important;
  padding: 0 !important;
  margin: 0 0.5em;
}

.alignDisc{
    display: flex;
align-items: center;
justify-content:space-between;
}
.discount:after,
.discount:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 0;
  width: 100%;
  height: 3px;
  background: rgb(225, 20, 20);

}
.discount:before {
  transform: rotate(25deg);
}
.discount:after {
  transform: rotate(-25deg);
}
.busyPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ed8620;
    color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
  }
  
  .busyPopup p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    font-family: 'cairo';
  }
  
.bBottomNav{
    background: #FFFFFF;
    box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.07);
    border-radius: 30px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    
    position: relative;
   z-index: 10;
    }
    .bBottomNav p{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 34px;
       
        letter-spacing: -0.3px;
        
        /* Gray 4 */
        
        color: #BDBDBD;
      
        margin-bottom: 0;
    }
    
    .bBottomNav div{
        text-align: center;
        cursor: pointer;
        position: relative;
        top:5px
       
    }
    
    
    .bBottomNav div:hover p{
        color: #633235;
    
    }
    .bBottomNav div:hover path{
        fill: #633235;
    
    }

    .bBottomNavT{
        background-color: white;
        width: 100%;
padding: 3em;
padding-top: 2em;
position: relative;
top:20px;
z-index: 5;
    }



          

    

    .bBottomNavT p{
        font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 49px;
/* identical to box height */


letter-spacing: -0.3px;
margin-bottom: 5px;
color: #E47100;
display: inline;
padding: 10px;
    }


    .bBottomNavT span{
        font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 49px;
/* identical to box height */


letter-spacing: -0.3px;

color: #E47100;
display: inline;
    }
    .bBottomNavT button{
        background: #F2F2F2;
        border-radius: 14px;
        font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 26px;
line-height: 49px;
letter-spacing: -0.3px;

 color: #f88819;
        background-color: white;
        border:  2px solid #f88819;
width: 100%;
padding: 0.5em 0;
cursor: pointer;
    }

    .bBottomNavT button:hover{
        color: white;
        background-color: #f88819;
      
     
    }


    
    .align{

   display: flex;
   justify-content: center;
   align-items: center;
    }
    .bBottomnv{
        position: fixed;
        
        bottom: 50px;
        width: 100%;
        height: 180px;
    }


      .BottomNav a.active div {
        border-top: 6px solid #633235;
        border-radius: 7px;
        height: 100%;
       
       padding: 6px 15px 0 15px;
        transform: rotatey(180deg);
        top: 0;
       }
       
      
       
        .BottomNav a.active img {
       
        transform: rotatey(-180deg);
        }
     .BottomNav a.active p{
        transform: rotatey(-180deg);
        color: #532a2d;
     }
     .BottomNav a.active{
        position: relative;
      top: 0;
     }

     .BottomNav a.active path{
        fill: #633235;
        }
        .BottomNav a.active .Svg{
            transform: rotatey(-180deg);
            }
      
     .bBottomNav a.active div {
        border-top: 6px solid #633235;
        border-radius: 7px;
        height: 100%;
       
       padding: 6px 15px 0 15px;
        transform: rotatey(180deg);
       }
       
       .bBottomNav a.active path{
       fill: #633235;
       }
       
       
        .bBottomNav a.active{
       
        transform: rotatey(-180deg);
        }
     .bBottomNav a.active p{
      
        color: #532a2d;
     }
     .bBottomNav a.active{
        position: relative;
        bottom: 10px;
     }

 a{
            text-decoration: none !important;
          
        }


  .arrow-button {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size:calc(16px + 0.5vw);

    line-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.3px; 
    color: #331900;
    display: flex;
    background-color: transparent;
    padding: 0;
    transition: all .3s ease;
    cursor: pointer;
    border: none;
    width: 3em;

border-radius: 0 0 16px 16px;

}

.arrow-button > .arrow {
    width: 13px;
    height: 13px;
    border-right: 3px solid  #331900;
    border-bottom: 3px solid  #331900;
    position: relative;
    transform: rotate(-220deg);

    transition: all .3s ease;
   margin: 0 10px;
}



.Div:hover .arrow-button > .arrow {
    transform: rotate(-220deg) translate(4px, 4px);
    border-color: text-hover-color;
}

.Div:hover .arrow-button > .arrow::before {
    opacity: 1;
    width: 8px;
}

.Div:hover .arrow-button {

    border-radius: 0 0 16px 16px;
}


.mainCategories:hover .arrow-button > .arrow {
    transform: rotate(-220deg) translate(4px, 4px);
    border-color: text-hover-color;
}

.mainCategories:hover .arrow-button > .arrow::before {
    opacity: 1;
    width: 8px;
}

.mainCategories:hover .arrow-button {

    border-radius: 0 0 16px 16px;
}


.enarrow-button {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size:calc(16px + 0.5vw);

    line-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.3px; 
    color: #331900;
    display: flex;
    background-color: transparent;
    padding: 0;
    transition: all .3s ease;
    cursor: pointer;
    border: none;
    width: 3em;
    transform: rotate(-185deg);
border-radius: 0 0 16px 16px;

}

.enarrow-button > .arrow {
    width: 13px;
    height: 13px;
    border-right: 3px solid  #331900;
    border-bottom: 3px solid  #331900;
    position: relative;
    transform: rotate(-220deg);

    transition: all .3s ease;
   margin: 0 10px;
}


.lpBtn a{
  width: 80%;
  text-align:    -webkit-right;;
}
.lpBtnEn a{
  
    text-align:    -webkit-left;;
  }

.Div:hover .enarrow-button > .arrow {
    transform: rotate(-220deg) translate(4px, 4px);
    border-color: text-hover-color;
}

.Div:hover .enarrow-button > .arrow::before {
    opacity: 1;
    width: 8px;
}

.Div:hover .enarrow-button {

    border-radius: 0 0 16px 16px;
}


.mainCategories:hover .enarrow-button > .arrow {
    transform: rotate(-220deg) translate(4px, 4px);
    border-color: text-hover-color;
}

.mainCategories:hover .enarrow-button > .arrow::before {
    opacity: 1;
    width: 8px;
}

.mainCategories:hover .enarrow-button {

    border-radius: 0 0 16px 16px;
}



.accordion-body label{
    font-family: 'Cairo' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 41px !important;
  text-align: right !important;
  letter-spacing: -0.3px !important;
  
  color: #828282 !important;
 padding: 0.5em;
  }
  .accordion-body .enadditional{
  padding-left: 0.5em ;
  padding-right: 0 !important;
  }
  .accordion-body .flex{
display: flex;
justify-content: space-between;
align-items: center;
  }

  .accordion-body .flex p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 49px;

    letter-spacing: -0.3px;
    
    color: #E47100;
    margin-bottom: 0;
      }
  
  .accordion-body input[type="radio"]{
    cursor: pointer !important;
    width: 20px;
    height: 20px;
   
  }
  input[type="radio"] {
	accent-color: #E47100 ;
    width: 20px;
    height: 20px;
}
input[type="checkbox"] {
	accent-color: #E47100 ;
    cursor: pointer;
}
.accordion-body .encol{
     text-align: left !important; 
     direction: ltr !important;
}
.accordion-body .encol2{
    text-align: left !important; 
   
}

.accordion-body .encol label{
   padding-left: 0.5em;
}
.accordion-body .encol2 label{
    padding-left: 0.5em;
 }

 .enrow{
     direction: ltr !important;
 }
 /*LandingPage*/

 .landingForm {
    text-align: right;


     }
     .shareForm{ 
        padding: 2em;
      
        box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
    
    }
    
    .foundUrl{
        height: 100vh;
        width: 100vw;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        padding-bottom: 17vh;
        align-items: flex-end;
        justify-content: center;
        }
        .foundUrl p{
        color: white;
        font-size: 5vw;
        font-family: 'Cairo';
        }
     .landingFormEn {
        text-align: left;

        padding-left: 1em ;
    
         }
         .landingFormEn form{
          
            padding-left: 2em !important;
        
             }
 .landingForm img{

height: calc(103.5vh - 48px);
position: absolute;
float: left;
left: -12px;
top: -20px;

 }
 .landingLang{
position: absolute;
left: 1em;
top: 1em;
padding: 0.3em;
border-radius: 10px;
box-shadow: 0px 6px 33px rgba(0, 0, 0, 0.15);
display: flex;
z-index: 12;
 }
 .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

 }
  .landingLang p{
   margin-bottom: 0;
 }  
 .landingLangAr{

    right: 1em;
  left: auto;
    
    
     }  

 .landingFormEn img{

    height: calc(103.5vh - 46px);
    position: absolute;
  
    right: -55px;
    top: -20px;
 }

 .landingForm button{
    border: 2px solid #E47100;
    border-radius: 8px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 34px;
display: block;
letter-spacing: -0.3px;
color: #E47100;
background-color: transparent;
width: 30%;
padding: 15px 0;
 }

 .landingForm button:hover{
    color: white;
background-color: #E47100;

 }

 .landingForm label{

    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 30px;

letter-spacing: -0.3px;
color: #E47100 !;
display: block;
margin-bottom: 2em;
 }
 .landingForm p{

    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 30px;

letter-spacing: -0.3px;
color: #E47100 !;
display: block;
margin-bottom: 0.5em;
 }
 .qrType{
     font-weight: 600 !important;
     font-size: 27px !important;
 }
 .landingForm input{
    background: #FFFFFF;
    box-shadow: 0px 6px 33px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 26px;
letter-spacing: -0.3px;
color: #828282;
border: none;
width: 40%;
padding: 1em 0;
padding-left: 20px;
margin-bottom: 0.5em;

 }
 .landingForm input:focus{
border: 2px solid #E47100 ;
outline: none !important;
 }

 .landingForm input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }

 .landingForm input[type=text] {
   -moz-appearance: textfield;
 }
 .landingForm input:focus {
  background-color: white !important;
  
  }
 
 .landingForm .lpAlign{
    display: flex;
    
    align-items: center;
    height: calc(100vh - 41px);
 }

.landingForm form{
flex: 1;
padding-right: 2em;
}

 .lpBtn{
     display: flex;
     justify-content: flex-end;
     margin-top: 5em;
 }

 .orderBtns button{
    margin-right: 2vw;
  }

.invoiceBtn{
    background-color: #e47100 !important;
    color: white !important;
    border: none !important;
 padding:0.5em 3.8em !important;
 margin-left: 2vw;
 margin-right: 0 !important;

}

.invoiceBtn:hover{
    background-color: #f98a1b !important;

}

.orderBtnsEn button{
    margin-Left: 2vw;
  }
  .lpLogo{
      width:25vw !important;
      min-width: 250px;
      position: static !important;
      height: auto !important;
      float: none !important;
      margin-bottom: 2em;
      }
      .bDwUqm{
          z-index: 0;
      }
      
      .lpAlign span{
          color: rgb(169, 15, 15);
          display: block ;
          margin-bottom: 2em;
         
      }
      .logoDiv{

height: 8vw;
background-repeat: no-repeat;
background-position: center;
background-size: contain;
      }

      .brnchName{
        display: inline-block !important;
        color: #e47100 !important;
        margin-bottom: 0 !important;
    }
    .vat {
        background: #fff;
        max-width: 800px;
        padding: 30px;
        margin: 30px auto;
        border-radius: 20px;
        text-align: center;
        box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
    }
      .invalid input{
        border: 2px solid rgb(169, 15, 15) !important;}
        
      .invalid input:focus{
        outline: none !important;
          border: 2px solid rgb(169, 15, 15) !important;}



          /*Invoice*/





          .invoiveLogo{
            width: 100%;
            height: 5em;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin-bottom: 0.5em;
          
          }
          .invoiceData div{
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .invoiceData{
            padding: 1em 0;
            border-bottom: 1px solid #E0E0E0;
        }
          
          .invoiceData p{
        font-family: 'Cairo';
        font-family: 'Cairo';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 37px;
            margin-bottom: 0.2em;
            letter-spacing: -0.3px;
            color: #4F4F4F;
          }
        
          .invoiceData .invoice-value {
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 34px;
            letter-spacing: -0.3px;
            color: #331900;
        }
          .invoiceTitle{
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 52px;
            letter-spacing: -0.3px;
            color: #331900;
            margin-bottom: 0;
          }
         
          .invoiceTable th{
            text-align: center !important;
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 600;
        
            font-size: 16px;
          }
          .invoiceTable td{
            text-align: start !important;
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }
          .invoiceTableDiv{
            box-shadow: none !important;
            padding: 1em 0;
            border-bottom: 1px solid #E0E0E0;
            border-radius: 0 !important;
          }
         .invoiceTotal1{
          font-family: 'Cairo';
          font-style: normal;
          font-weight: 400 !important;
          font-size: 20px !important;
          line-height: 30px;
          letter-spacing: -0.3px;
          color: #331900 !important;
        
        }
        .invoiceTotal2{
        
          font-family: 'Cairo';
          font-style: normal;
          font-weight: 700 !important;
          font-size: 20px !important;
          letter-spacing: -0.3px;
          color: #331900 !important;
          
        }
        .foodModal .modalBody{
padding: 1em;
        }
      .foodModal  .modal-content{
          left: 50%;
      }
      .foodModal .confirm{
          width: unset;
      }
        .foodModal .modalBtns{
            margin-top: 1em;
        }
        .ciBottom button:disabled{
            cursor: not-allowed;
            background-color: #eeb378;
            }
        .caution{
          text-align: start;
          padding-top: 1em ;
        }
        .invoiceDiv{
          max-width: 500px !important;
        }
        .invoiceTableDiv .table{
          min-width: 0 !important; 
        }
        .bBottomNavT button:disabled{
            cursor: not-allowed;
            background-color: #eeb378;
border: none;
color: white;

        }
        .bBottomNavT button:disabled:hover{
            background-color: #eeb378;
            border: none;
            
        }
.invoiceTableDiv td{
  text-align: center !important;
}
        /*Tap style*/

        .form-row {
            width: 70%;
            float: left;
            background-color: #ededed;
        }
        #card-element {
        background-color: transparent;
        height: 40px;
        border-radius: 4px;
        border: 1px solid transparent;
        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
        }
        
        #card-element--focus {
          box-shadow: 0 1px 3px 0 #cfd7df;
        }
        
        #card-element--invalid {
          border-color: #fa755a;
        }
        
        #card-element--webkit-autofill {
          background-color: #fefde5 !important;
        }
        
        #submitbutton,#tap-btn{
        align-items:flex-start;
        background-attachment:scroll;background-clip:border-box;
        background-color:rgb(50, 50, 93);background-image:none;
        background-origin:padding-box;
        background-position-x:0%;
        background-position-y:0%;
        background-size:auto;
        border-bottom-color:rgb(255, 255, 255);
        border-bottom-left-radius:4px;
        border-bottom-right-radius:4px;border-bottom-style:none;
        border-bottom-width:0px;border-image-outset:0px;
        border-image-repeat:stretch;border-image-slice:100%;
        border-image-source:none;border-image-width:1;
        border-left-color:rgb(255, 255, 255);
        border-left-style:none;
        border-left-width:0px;
        border-right-color:rgb(255, 255, 255);
        border-right-style:none;
        border-right-width:0px;
        border-top-color:rgb(255, 255, 255);
        border-top-left-radius:4px;
        border-top-right-radius:4px;
        border-top-style:none;
        border-top-width:0px;
        box-shadow:rgba(50, 50, 93, 0.11) 0px 4px 6px 0px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
        box-sizing:border-box;color:rgb(255, 255, 255);
        cursor:pointer;
        display:block;
        float:left;
        font-family:"Helvetica Neue", Helvetica, sans-serif;
        font-size:15px;
        font-stretch:100%;
        font-style:normal;
        font-variant-caps:normal;
        font-variant-east-asian:normal;
        font-variant-ligatures:normal;
        font-variant-numeric:normal;
        font-weight:600;
        height:35px;
        letter-spacing:0.375px;
        line-height:35px;
        margin-bottom:0px;
        margin-left:12px;
        margin-right:0px;
        margin-top:28px;
        outline-color:rgb(255, 255, 255);
        outline-style:none;
        outline-width:0px;
        overflow-x:visible;
        overflow-y:visible;
        padding-bottom:0px;
        padding-left:14px;
        padding-right:14px;
        padding-top:0px;
        text-align:center;
        text-decoration-color:rgb(255, 255, 255);
        text-decoration-line:none;
        text-decoration-style:solid;
        text-indent:0px;
        text-rendering:auto;
        text-shadow:none;
        text-size-adjust:100%;
        text-transform:none;
        transition-delay:0s;
        transition-duration:0.15s;
        transition-property:all;
        transition-timing-function:ease;
        white-space:nowrap;
        width:150.781px;
        word-spacing:0px;
        writing-mode:horizontal-tb;
        -webkit-appearance:none;
        -webkit-font-smoothing:antialiased;
        -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
        -webkit-border-image:none;
        
        }
        /* ######## */
        
        @media screen and (max-width: 500px) {
            .foundUrl{
                padding-bottom: 2.5vw !important;
            }
            .foundUrl p{
                font-size: 8vw !important;
            }
          .invoiceTitle{
            font-size: 20px;
            line-height: 0;
            margin-bottom: 1em;
          }
        .invoiceData p{
          font-size: 14px;
        }
        .invoiceData .invoice-value{ 
          font-size: 13px;
        }
        .invoiceTotal1{
          font-size: 15px !important;
          line-height:25px;
          margin-bottom: 0;
        }
        .invoiceTotal2{
          font-size: 18px !important;
        
        }
        .invoiceTable{
          min-width: 0 !important;
        }
        .invoiceDiv{
          padding: 15px !important;
        }
        }


          @media screen and (max-width: 1154px) {

            .landingForm button {
display: inline-block;
                width: 80%;
                padding: 10px 0;
            }
            .lpBtn a{
                text-align: center;
            }
.landingForm input{
    width: 80%;
    padding: 15px 0;
    font-size: 18px;
}
.landingForm label{
   /* font-weight: 600;*/
   font-size: 28px;
}

            .lpImage {
             display: none;
            }
            .landingForm .lpAlign {
                justify-content: center;


            }
           .lpAlign form{
               text-align: center !important;
               background-color: white;
               padding: 2em 0;
           max-width: 800px;
           margin: 0 2vw;
             box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
             border-radius: 12px;
            }
             .lpBtn{
               
                justify-content: center;
            }
          } 
          
          @media screen and (max-width: 767px) {
            .foundUrl{
                box-shadow: none !important;
                border-radius: 0 !important;
                padding-bottom: 10vh !important;
height:58vh ;
 border-radius: 10px;  
            }
            .foundUrl p{
                font-size: 7vw;
            }
            .fountContainer{
                height: 100vh;
                display: flex;
                align-items: flex-end;
background-color: #5f2326; 
    }
            .foodItemImg {
           
           height: 30vw;
           max-height: none;
           min-height: 100px;
            }
            .footerLink{
                display: block;
                text-align: center;
            }
        }

      
@media screen and (max-width: 576px) {
    .ciTopFlex {
   
        justify-content: flex-end;
        display: none !important;
    }
    .ciTopFlexMobile{
        display: flex !important;
    }
    .ciP1{
        font-size: 25px;
         /* font-weight: 600;*/
        
    }
.ciP2{
    font-size: 20px;
}
.bottomPadding{
    padding: 1.2em !important;
}
.ciB-1{
    /* font-weight: 600;*/
    font-size: 20px;
}
.ciB-2{
    font-size: 20px;
}
.ciB-3{
    font-size: 20px;
}


.ciBottom button {
     /* font-weight: 600;*/
    font-size: 20px;
}

.ciDetails-div{
    padding: 2em 1.2em !important;
}

.mmBackButton p {

   /* font-weight: 600;*/
    margin-bottom: 0;
}
.mmFoodItem .wrapText2{
    margin-bottom: 0;
}


.mmName{
    font-size: 14px;
   /* font-weight: 600;*/
}
.mmBottom p{
     /* font-weight: 600;*/
    font-size: 13px;
}
.mmFoodItem{
padding: 0.5em 0.1em;
}

.mmBottom button{
padding: 6px;
}

}
@media screen and (min-width: 576px) {
 
    .ciTopFlexMobile{
        display: none !important;
    }
    
   
}
@media screen and (max-width: 670px) {
    .landingFormEn form {
        padding-left: 0 !important;
    }
    .landingForm p {
    /* font-weight: 600;*/

      }
      .qrType  {
font-weight: 500;
      }
    
    
    }



    @media screen and (max-width: 767px) {
        .orderBtns button{margin: 0;}
        .invoiceBtn{
            margin-top: 1em !important;
        }
        .btn .btn1{
            padding: 0 20px;
          }
          .enciP1{
             /* font-weight: 600;*/
          }
          .enbName{
font-weight: 600;
          }
          .enbNote p{
           /* font-weight: 600;*/
          }
          .btn .btn2{
            padding: 0 20px;
        }
        .btn p {
        font-size: 25px;
        }
    
        .bBottomNavMobile{
            padding: 0.5em;
            padding-bottom: 1.5em;
       
        }
        .bBottomNavT p {
            white-space: nowrap;
            font-size: 23px !important;
        }
        .bNote p {
    
         /* font-weight: 600;*/
        }
        .bBottomNavT p{
           /* font-weight: 600;*/
        }
        .alignBtn{
    display: flex;
    align-items: center;
        }
        .partition{
            /*   height: 105px !important;*/

           height: 230px !important;
        }
        .bBottomNavMobile button{
          /* font-weight: 600;*/
            padding:0 !important;
            font-size: 23px !important;
        }
        .bTop p{
           
        } 
        .bEmpty{
   /* font-weight: 600;*/
        }
        .bEmpty button{
             /* font-weight: 600;*/
    
            white-space: nowrap;
            padding: 0.5em ;
            width: 75%;
                    }
        .bItem{
    padding: 0.5em;
        }
        .bName {
          /* font-weight: 600;*/
            margin-bottom: 0;  
            font-size: 20px;
        }
        .bDetails{
            font-size: 16px;
        }
        .bBottomnv{
         
            position: fixed;
            bottom: 0 ;
         
        }
       .bBottomNavEmpty {
         
            bottom: -110px !important;
        }
    
        .bBottomnv2{
         
            position: fixed;
            bottom: 10px !important;
         
        }
        .loaderDiv{
            padding-top: 2em;
        }
        .enbNote p{
            font-size: 16px;
        }
       
      }
      @media screen and (max-width: 575px) {
        .orderBtns button{margin: 0 2vw;}
        .invoiceBtn{
            margin-right:  2vw !important;
        }
      }
      @media screen and (max-width: 559px) {
        .invoiceBtn{
            padding: 0.5em 2.5em;
        }
 }
   
    @media screen and (max-width: 500px) {
      
    .landingForm p {
     /* font-weight: 600;*/
      font-size: 18px !important;
         }
.langBtn{
font-size: 18px;
}
    .landingForm button{

        font-size: 14px !important;
      }
      .mpP-2{
   /* font-weight: 600;*/
font-size: 13px;
margin-bottom: 0;
      }
    

      .mpP-1{
        /* font-weight: 600;*/
        font-size: 14px;
              }
              div.scrollmenu button {
                  font-size: 14px;
              }

      .langSwitch p{
          font-size: 14px
      }

      .langSwitch{
          text-align: initial;
      }
      .mpNav img{
width: 80px;
      }
      .BottomNav p{
        font-size: 14px;
      }
      .mmBackTitle{
          font-size: 14px !important;
           /* font-weight: 600;*/
       margin: 0 !important;
      }
      .mainCategories p{
font-size: 13px;

      }
      .mpNav .mmBack{
          width: 40px;
          height: 40px;
          padding: 0;
      }


      .ciImage button{
          width: 40px;
          height: 40px;
      }

.ciP1{
    font-size: 14px;
}
.enciP1{
    font-size: 14px;
}
.enciP2{
    font-size: 13px;
}
.ciP2{
font-size: 13px;
}
.accordion-button{
    font-size: 14px !important; 
}
.accordion-body label{
    font-size: 13px !important;  
}
.btn p{
    line-height: 50.5px;
    font-size: 14px !important;  
    height: unset;
    padding: 0px 10px;
    padding-bottom: 1.7px;
} 
.btn .btn2{
font-size: 14px;
height: unset;
}
.btn .btn1{
    font-size: 14px;
    height: unset;
}

.btn .enbtn1{
    font-size: 14px;
    height: unset;
}
.btn .enbtn2{
    font-size: 14px;
    height: unset;
}
.ciB-1{
    font-size: 13px;
}

.ciB-2{
font-size: 14px;
}
.ciB-3{
    font-size: 13px;
}
.enciB-1{
    font-size: 13px;
}
.enciB-2{
    font-size: 14px; 
}
.enciB-3{
    font-size: 13px; 
}
.ciBottom button{
    font-size: 13px;
}
.cstmrChoice{
   align-items: center;
    display: flex;
}
.accordion-body .flex p {

    font-size: 13px;
      /* font-weight: 600;*/
}
.offer{
    font-size: 14px; 
    /* font-weight: 600;*/
    line-height: 30px;
    width: 250px;
    margin: auto;
    margin-bottom: 1em;

}
.orderRec button{
    font-size: 13px;
}
.odderBckg{
    height: 70px;
}
.orderRec p{
     /* font-weight: 600;*/
}
.Modal .continue{
    font-size: 13px;
}
.offerAfter{
    font-size: 14px;
}
.offer-middle{
    font-size: 14px;
}
.offerBefore{
font-size: 14px;
}
.phoneForm input{

    font-size: 13px;     
}
.confirm{
    font-size: 14px;  
}
.cancel{
    font-size: 14px;  
}
.addAlert p{
    font-size: 14px;
   /* font-weight: 600;*/  
}
.addAlert span{
    font-size: 14px;
}
.bEmpty{
    font-size: 25px;
}
.bEmpty button{
    font-size: 18px;  
}
.bck{
    width: 40px !important;
    height: 40px !important;
    padding: 0;
}
.bdeleteAll{
font-size: 14px !important;
}
.bTop p{

    font-size: 14px;}
    .bTop{
        padding-bottom: 0 !important;
    }
.bName{
    font-size: 13px !important  

}
.enbName{

    font-size: 13px !important   
}
.bDetails{
    font-size: 14px !important;  
}
.bPrice{
    font-size: 14px !important;  
}
.bBtn{
    font-size: 14px !important 
}
.bQuan{
    font-size: 14px !important 
}
.bNote p{
    font-size: 13px  
}
.bNote input[type="text"]{
    font-size: 13px  
}
.enbNote input[type="text"]{
    font-size: 13px  
}
.paymentTYpe label{
    font-size: 13px  
}
.bBottomNavMobile button{
 font-size: 13px  !important 

}
.bBottomNavT p{
    font-size: 13px   !important  
}
.bBottomNav p{
    font-size: 14px   !important    
}
    }



    @media screen and (max-width: 440px) {
        .landingFormEn .welcome{
          
            display: flex;
    flex-direction: column-reverse;
        }
      
        
        }

