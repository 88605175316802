
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&family=Lato:wght@700&display=swap');

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
.App {

}


html{
  width: 100%;
     height: 100%;
}

body{
  background: #E5E5E5;
height: 100%;
}

body {
  position: relative;
  min-height: 100%;
padding-bottom: 130px !important;
}
/*
body::after {
  content: '';
  display: block;
  height: 200px; 
}
*/
.pointer{
  cursor: pointer;
}

.accordion-button{
 
 font-family: 'Cairo' !important;
font-style: normal !important;
font-weight: 600 !important;
font-size: 24px !important;
line-height: 45px !important;
letter-spacing: -0.3px !important;

color: #633235 !important;
border: 0.01px solid #E0E0E0 !important;
border-radius: 15px 15px 0 0 !important; 

}
.accordion-button div{

width: 100%;
  display: flex !important;
justify-content: space-between !important;
}

.accordion-button:focus {
  
  border-color: transparent !important;
  outline: none !important;
  box-shadow: 0 0 0 0.1rem #E0E0E0  !important;
}



.accordion-item {
  border: none !important;
  margin-top: 1%;
}
.accordion-body {
  text-align: right !important;
}


.accordion-button:not(.collapsed) {

  background-color: transparent !important; 
 
}
.accordion-item {
  border: 1px solid #E0E0E0 !important;
  border-radius: 15px !important;
  margin-bottom: 1em;
}


 .collapsed{
 
  border-radius: 15px !important;
}
.accordion-button .rotate{
  transition:transform 0.5s;
  color: #E47100;
}

.collapsed .rotate{
  transform-origin: 50% 50%;
  transform: rotate(-180deg);
  transition:transform 0.5s;

  color: #633235 !important;
}

.carousel{

  filter: drop-shadow(2px 3px 7px rgba(80, 10, 10, 0.4)) !important;

}
.carousel img{

 
  border-radius: 10px !important;
}

.carousel-indicators{
  direction: ltr;
}

.accordion-button:not(.collapsed)::after {
  background-image: none !important;
}
.accordion-button::after {
  background-image: none !important;
}


@media screen and (-webkit-min-device-pixel-ratio:0) { 
  textarea,
  input {
    font-size: 16px !important;
  }
}