

.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-family: 'Cairo';
    background-color: rgba(97, 95, 95, 0.269);
  }
  #downloadIcn{
      position: relative;
      bottom: 2px;
      margin: 0 5px;
  }
  
  .header .invoiveLogo{
    height: 15vw;
    width: 15vw;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header p{
    white-space: nowrap;
    font-size: calc( 6px + 2vw);
    margin: 0;
    line-height:  calc( 5px + 2.5vw);
    text-align: center;
  }
  .body{
    padding: 15px;
    margin: 15px 0;
    font-family: 'Cairo';
  
  }
  .body .dataDiv{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: calc( 5px + 1vw);
  }
  .body .dataDiv p{
    margin-bottom: 1.7vw;
    margin-top: 0;
  }
  .body .dataDiv span{
  margin: 0 18px;
  font-weight: 500 !important;
  
  }
  .body table{
    text-align: right;
    font-size: calc( 5px + 0.8vw);
    border: 1px solid black;
  border-collapse: collapse;
    background-color: rgba(97, 95, 95, 0.269);
  table-layout: fixed;
  overflow: scroll;
  width:100%;
  
  }
  .body tbody{
  background-color: white;
  }
  .body th{
    padding: 5px !important;
  }
  .body td{
    padding: 5px;
    border: 1px solid black;
  
  }
  .body .productsTable{
  margin-top: 2em;
  }
  .body .totalTable{
    margin-top: 1em;
    width: 100%;
  }
  .body .totalTable td{
  padding: 2px 15px;
  
  }
  .pdfBtn{
    text-align: right;
  margin: 1.5em 0;
  }
  .pdfBtn button{
    border: none;
    color: white;
    font-size: calc( 8px + 1vw);
    padding: 5px 15px;
    font-family: 'Cairo';
    border-radius: 8px;
    background-color: #e47100;
  cursor: pointer;
  
  }
  
  .body button:hover{
    background-color: #f1861a;
  
  
  }
  .body .totalTable th{
    border: 1px solid black;
    padding: 2px 15px;
  
  }
  .body .invoiceFooter{
    margin-top: 3em;
    font-weight: 600;
    font-size: calc(5px + 1vw);
    text-align: center;
    line-height: calc(10px + 2vw);
  }
  
  @media screen and (max-width: 767px){
      .body .totalTable{
          width: 100%;
      }
  }
